import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Api from "../components/Api";
import Layout from "../components/Layout";
import MeetFounder from "../components/MeetFounder";
import WhatWeDo from "../components/WhatWeDo";
import WhiteBox from "../components/WhiteBox";
import GlobalState from "../GlobalState";

export default function About() {
    const [apiOptions, setApiOptions] = useState({});
    const [aboutPage, setAboutPage] = useState();
    const { seoTags } = useContext(GlobalState);

    useEffect(() => {
        setApiOptions({
            url: '/about-page',
            method: 'get',
            then: (r) => {
                setAboutPage(r.data)
            }
        });
    }, []);

    useEffect(() => {
        window.triggerScroll();
    });

    return (
        <Layout activePage="about" pinkFlower={true}>
            {seoTags ?
                <Helmet>
                    <title>{seoTags[1].title}</title>
                    <meta name="title" content={seoTags[1].title} />
                    <meta name="description" content={seoTags[1].description} />

                    <meta property="og:title" content={seoTags[1].title} />
                    <meta property="og:description" content={seoTags[1].description} />
                    <meta property="og:image" content={seoTags[1].image} />

                    <meta property="twitter:title" content={seoTags[1].title} />
                    <meta property="twitter:description" content={seoTags[1].description} />
                    <meta property="twitter:image" content={seoTags[1].image} />
                </Helmet>
                :
                null
            }
            <Api options={apiOptions} />
            {aboutPage ?
                <>
                    <div className="position-relative">
                        <img className="grey-curl" src="/assets/images/fixed/grey-curl.svg" alt="curl" />
                        <div className="container padding-section-about position-relative" animate="left">
                            <WhatWeDo
                                column1="col-lg-4  order-lg-1 order-2 pt-lg-0 mt-lg-0 pt-md-5 mt-md-3 pt-sm-5 mt-sm-5 pt-5 mt-5"
                                column2="col-lg-6 add-padding-about order-lg-2 order-1 pb-lg-0 pb-sm-5 pb-md-0 pb-5"
                                Button={true}
                                class="row align-items-center flex-row-reverse justify-content-end"
                                title={aboutPage.aboutSettings.banner_title}
                                subtitle={aboutPage.aboutSettings.banner_description}
                                firstRatio="ratio what-we-do what-we-do-first-image about"
                                firstImage={process.env.REACT_APP_BASE_URL + aboutPage.aboutSettings.banner_image_one}
                                secondRatio="ratio what-we-do what-we-do-second-image about"
                                secondImage={process.env.REACT_APP_BASE_URL + aboutPage.aboutSettings.banner_image_two}
                                dots="dots-about"
                                flower="flower-about"
                            />
                        </div>
                    </div>

                    <div className="container padding-about-bottom" animate="right">
                        <div className="row justify-content-center">
                            {
                                aboutPage.aboutBoxes.map((aboutBox, index) =>
                                    <div className="col-lg-4 col-md-6 mb-4" key={index}>
                                        <WhiteBox
                                            title={aboutBox.title}
                                            subtitle={aboutBox.description}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="" animate="left">
                        <div className="pink-bg py-5 position-relative">
                            <img className="mission-section-flower" src="/assets/images/fixed/mission-flower.svg" alt="logo" />
                            <img className="mission-bg" src="/assets/images/fixed/mission-bg.svg" alt="logo" />
                            <img className="dots-mission" src="/assets/images/fixed/dots.svg" alt="logo" />
                            <div className="container">
                                <div className="row justify-content-center text-center mission">
                                    <div className="col-lg-6">
                                        <h2 className="main-title white mb-3">{aboutPage.aboutSettings.mission_title}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: aboutPage.aboutSettings.mission_description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container padding-about-bottom" animate="right">
                        <div className="row justify-content-md-between justify-content-center align-items-center">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-5 col-md-6 text-center order-md-2 order-3">
                                <h2 className="main-title pink mb-3">{aboutPage.aboutSettings.story_title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: aboutPage.aboutSettings.story_description }} />
                            </div>
                            <div className="col-lg-1 d-md-none"></div>
                            <div className="col-lg-5 col-md-6 col-sm-9 col-9 order-md-3 order-2 pb-md-4 pb-4">
                                <div className="ratio story-ratio">
                                    <img className="custom-shadow" src={process.env.REACT_APP_BASE_URL + aboutPage.aboutSettings.story_image} alt={aboutPage.aboutSettings.story_title} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" padding-about-bottom" animate="left">
                        <MeetFounder
                            image={process.env.REACT_APP_BASE_URL + aboutPage.aboutSettings.founder_image}
                            title={aboutPage.aboutSettings.founder_title}
                            text={aboutPage.aboutSettings.founder_description}
                        />
                    </div>

                    <div className="container padding-about-bottom" animate="right">
                        <div className="row align-items-center">
                            <h2 className="main-title pink mb-4 text-center">{aboutPage.aboutSettings.products_title}</h2>
                            <div className="col-lg-3  ">
                                <p className="mb-4">{aboutPage.aboutSettings.products_description}</p>
                                <div className="">
                                    {aboutPage.aboutProductFeatures.map((feature, index) =>
                                        <div className="d-flex pb-3" key={index}>
                                            <img src="/assets/images/fixed/close-black.svg" alt="x" />
                                            <p className="mb-0 ms-3">{feature.title}</p>
                                        </div>
                                    )
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 mb-md-4 mb-4">
                                <div className="ratio products-image">
                                    <img className="shadow" src={process.env.REACT_APP_BASE_URL + aboutPage.aboutSettings.product_image} alt={aboutPage.aboutSettings.products_title} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="row">
                                    {aboutPage.aboutProductBrands.map((brand, index) =>
                                        <div className="col-lg-12 col-md-6 col-sm-6">
                                            <div className="d-flex pb-3 " key={index}>
                                                <img src="/assets/images/fixed/small-flower.svg" alt="flower" />
                                                <p className="mb-0 ms-3">{brand.title}</p>
                                            </div>
                                        </div>
                                    )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                null
            }
        </Layout>
    )
}