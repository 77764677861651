import { useRef, useState } from "react";
import Button from "./Button";

export default function Banner(props) {

    const videoRef = useRef();
    const [playing, setPlaying] = useState(false);

    const onPlay = () => {
        setPlaying(true);
    }

    const onPause = () => {
        setPlaying(false);
    }

    const playClick = () => {
        videoRef.current.play();
    }

    const pauseClick = () => {
        videoRef.current.pause();
    }

    return (
        <div className="row align-items-center justify-content-between h-100">
            <div className="col-lg-5 col-md-6 order-md-1 order-2">
                <h1 className="service-title pb-4">{props.title}</h1>
                <div className="button-arrow position-relative text-end d-inline-flex">
                    <div className="menu-link" onClick={() => props.setCalendlyOpen(true)}>
                        <Button
                            buttonClass="button-link add-space mb-0"
                            buttonColor="button pink-button shadow"
                            label={props.buttonLabel}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-1 d-md-none"></div>
            <div className="col-lg-6 col-md-6 order-md-2 order-1 mb-lg-0 mb-sm-4 mb-4">
                <div className={props.video ? " ratio service-video" : "ratio service-banner"}>
                    {
                        props.video ?
                            <>
                                <video muted loop autoPlay playsInline controls={playing} onPlay={onPlay} onPause={onPause} ref={videoRef}>
                                    <source alt={props.title} src={props.video} />
                                </video>
                                {
                                    playing ?
                                        // <div  >
                                        <svg onClick={pauseClick} className="pause-btn" xmlns="http://www.w3.org/2000/svg" width="173.421" height="173.421" viewBox="0 0 173.421 173.421">
                                            <g transform="translate(-0.421 -0.421)">
                                                <g transform="translate(0.421 0.421)">
                                                    <path className="fill-button" d="M148.027,25.394A86.726,86.726,0,1,0,173.421,86.71,86.439,86.439,0,0,0,148.027,25.394Zm-7.432,115.2a76.259,76.259,0,0,1-107.769,0,76.686,76.686,0,0,1-22.3-53.884,76.181,76.181,0,1,1,152.363,0A76.685,76.685,0,0,1,140.595,140.595Z" fill="#eed3d3" />
                                                </g>
                                                <g transform="translate(48.921 40.221)">
                                                    <path className="fill-button" d="M16.675,0A13.675,13.675,0,0,0,3,13.675V80.1a13.675,13.675,0,0,0,27.35,0V13.675A13.675,13.675,0,0,0,16.675,0Zm5.861,80.1a5.861,5.861,0,0,1-11.722,0V13.675a5.861,5.861,0,0,1,11.722,0Z" transform="translate(0)" fill="#f1d2d1" stroke="#f1d2d1" strokeWidth="1" />
                                                    <path className="fill-button" d="M27.675,0A13.675,13.675,0,0,0,14,13.675V80.1a13.675,13.675,0,0,0,27.35,0V13.675A13.675,13.675,0,0,0,27.675,0Zm5.861,80.1a5.861,5.861,0,0,1-11.722,0V13.675a5.861,5.861,0,0,1,11.722,0Z" transform="translate(31.979)" fill="#f1d2d1" stroke="#f1d2d1" strokeWidth="1" />
                                                </g>
                                            </g>
                                        </svg>
                                        // </div>
                                        :
                                        // <div className="play-btn" onClick={playClick} >
                                        <svg className="play-btn" onClick={playClick} id="_001-play" data-name="001-play" xmlns="http://www.w3.org/2000/svg" width="173.421" height="173.421" viewBox="0 0 173.421 173.421">
                                            <g id="Group_1122" data-name="Group 1122">
                                                <g id="Group_1121" data-name="Group 1121">
                                                    <path className="fill-button" id="Path_13038" data-name="Path 13038" d="M148.027,25.394A86.726,86.726,0,1,0,173.421,86.71,86.439,86.439,0,0,0,148.027,25.394Zm-7.432,115.2a76.259,76.259,0,0,1-107.769,0,76.686,76.686,0,0,1-22.3-53.884,76.181,76.181,0,1,1,152.363,0A76.685,76.685,0,0,1,140.595,140.595Z" fill="#eed3d3" />
                                                </g>
                                            </g>
                                            <g id="Group_1124" data-name="Group 1124" transform="translate(51.82 39.524)">
                                                <g id="Group_1123" data-name="Group 1123">
                                                    <path className="fill-button" id="Path_13039" data-name="Path 13039" d="M183.394,117.57a23.716,23.716,0,0,1-1.652-2.065l-.413-.413a7.415,7.415,0,0,0-2.065-1.445L148.71,96.1a.2.2,0,0,1-.206-.206L117.742,78.138a11.655,11.655,0,0,0-8.671-1.239,12.1,12.1,0,0,0-7.019,5.368,15.565,15.565,0,0,0-1.239,2.684c-.206.619-.206,1.445-.413,2.271v72.052a11.279,11.279,0,0,0,11.355,11.355,9.351,9.351,0,0,0,3.3-.619,12.614,12.614,0,0,0,2.89-1.445L148.3,151.016l.413-.206,30.555-17.549c.206,0,.206-.206.413-.206a11.6,11.6,0,0,0,4.955-6.813A10.769,10.769,0,0,0,183.394,117.57Zm-8.877,6.4a.444.444,0,0,1-.413.413H173.9l-30.349,17.755-.206.206-30.349,17.549c-.206,0-.206.206-.413.206a.2.2,0,0,0-.206.206h-.206c-.206,0-.413-.206-.619-.206a.758.758,0,0,1-.206-.619V87.841c.206-.206.413-.413.619-.413h.619l30.555,17.549.206.206L174.1,122.938l.206.206.206.206Q174.517,123.661,174.517,123.97Z" transform="translate(-100.4 -76.578)" fill="#eed3d3" />
                                                </g>
                                            </g>
                                        </svg>
                                    // </div>
                                }
                            </>
                            :
                            <img src={props.banner} alt="" id="image-on-video" />
                    }
                </div>
            </div>
        </div>
    )
}