import { useContext, useEffect, useState } from "react";
import Galleries from "../components/Galleries";
import GalleryPopup from "../components/GalleryPopup";
import Layout from "../components/Layout";
import Popup from "../components/Popup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Api from "../components/Api";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from "react-helmet";
import GlobalState from "../GlobalState";


export default function Gallery() {

    const [swiper, setSwiper] = useState(null);
    const [galleryOpen, setGalleryOpen] = useState(-1);
    const [apiOptions, setApiOptions] = useState({});
    const [galleryDetails, setGalleryDetails] = useState();
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [apiOptionsGallery, setApiOptionsGallery] = useState({});
    const [gallery, setGallery] = useState([])
    const { seoTags } = useContext(GlobalState);


    useEffect(() => {
        setApiOptions({
            url: '/gallery-page',
            method: 'get',
            then: (r) => {
                setGalleryDetails(r.data)
            }
        });
    }, []);

    useEffect(() => {
        setApiOptionsGallery({
            url: '/gallery-page?page=' + page,
            method: 'get',
            then: (r) => {
                setGallery([ ...gallery, ...r.data.galleryItems.data])
                setHasMore(page !== r.data.galleryItems.last_page);
                window.triggerScroll();
            }
        }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    function handleClickOutside(event) {
        if (!event.target.closest('.gallery')) {
            setGalleryOpen(-1)
        }
    }

    useEffect(() => {
        if ((swiper) && (swiper.slideTo) && (galleryOpen) !== -1) {
            swiper.slideTo(galleryOpen, 0);
        }
    }, [galleryOpen, swiper]);

    useEffect(() => {
        window.triggerScroll();
    });

    return (
        <Layout activePage="gallery">
            {seoTags ?
                <Helmet>
                    <title>{seoTags[3].title}</title>
                    <meta name="title" content={seoTags[3].title} />
                    <meta name="description" content={seoTags[3].description} />

                    <meta property="og:title" content={seoTags[3].title} />
                    <meta property="og:description" content={seoTags[3].description} />
                    <meta property="og:image" content={seoTags[3].image_path} />

                    <meta property="twitter:title" content={seoTags[3].title} />
                    <meta property="twitter:description" content={seoTags[3].description} />
                    <meta property="twitter:image" content={seoTags[3].image_path} />
                </Helmet>
                :
                null
            }

            <Api options={apiOptions} />

            {galleryDetails && (
                <>
                    <div className="position-relative gallery-banner">
                        <img className="gallery-right-image" src={process.env.REACT_APP_BASE_URL + galleryDetails.gallerySettings.second_image} alt="banner" animate="bottom-animate" />
                        <div className="gallery-right-box" animate="left-animate"></div>
                        <img className="grey-curl-gallery" src="/assets/images/fixed/grey-curl.svg" alt="grey" />

                        <img className="gallery-left-image" src={process.env.REACT_APP_BASE_URL + galleryDetails.gallerySettings.first_image} alt="banner" animate="right-animate" />
                        <div className="gallery-left-box" animate="top-animate"></div>

                        <div className="container position-relative text-position-gallery">
                            <div className="row justify-content-center">
                                <div className="col-lg-3 text-center">
                                    <h1 className="title-banner-gallery mb-lg-5 mb-md-5 mb-sm-5 mb-0" animate="top-animate" >{galleryDetails.gallerySettings.title}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <InfiniteScroll
                        scrollableTarget="body"
                        next={() => setPage(page + 1)}
                        dataLength={gallery.length}
                        hasMore={hasMore}
                    >
                        <div className="container gallery-padding" >
                            <div className="row justify-content-center">
                                {console.log(gallery)}
                                {
                                    gallery.map((galleryImages, index) =>
                                        <div className="col-lg-4 col-md-6 col-sm-6" animate="left" onClick={() => setGalleryOpen(index)} key={index}>
                                            <Galleries
                                                id={galleryImages.id}
                                                image={process.env.REACT_APP_BASE_URL + galleryImages.image}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </InfiniteScroll>
                    <Popup
                        isOpen={galleryOpen !== -1}
                        closePopup=" close-popup text-end"
                        close={() => setGalleryOpen(-1)}
                        whiteButton={true}
                        centeredSlides={true}
                        handleClickOutside={(e) => handleClickOutside(e)}
                        modalWindowClass="col-lg-7 col-md-8 col-sm-9 col-9"
                    >
                        <div className="position-relative gallery">
                            {galleryOpen !== -1 ?

                                <div className="position-relative gallery">
                                    <Swiper onSwiper={setSwiper} modules={[Navigation]} className="gallery-popup" spaceBetween={20} navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev'
                                    }}>
                                        {
                                            gallery.map((galleryImages, index) =>
                                                <SwiperSlide key={index}>
                                                    <GalleryPopup
                                                        image={process.env.REACT_APP_BASE_URL + galleryImages.image}
                                                    />
                                                </SwiperSlide>
                                            )
                                        }
                                    </Swiper>

                                    <div>
                                        <div className="swiper-button-prev gallery-swiper">
                                            <svg id="Component_15_9" data-name="Component 15 – 9" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                                <g className="gallery-arrow" id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#fff" strokeWidth="2">
                                                    <circle cx="25" cy="25" r="25" stroke="none" />
                                                    <circle cx="25" cy="25" r="24" fill="none" />
                                                </g>
                                                <path className="fill-arrow" id="fi-rr-arrow-small-right" d="M15.407,6.518a1.77,1.77,0,0,1,0,2.5L9.579,14.851l18.433.012a1.77,1.77,0,0,1,0,3.54L9.577,18.391l5.829,5.829a1.77,1.77,0,1,1-2.5,2.5L6.556,20.377a5.311,5.311,0,0,1,0-7.511L12.9,6.518A1.77,1.77,0,0,1,15.407,6.518Z" transform="translate(7.391 8.596)" fill="#fff" />
                                            </svg>
                                        </div>
                                        <div className="swiper-button-next gallery-swiper">
                                            <svg id="Component_15_8" data-name="Component 15 – 8" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                                <g className="gallery-arrow" id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#fff" strokeWidth="2">
                                                    <circle cx="25" cy="25" r="25" stroke="none" />
                                                    <circle cx="25" cy="25" r="24" fill="none" />
                                                </g>
                                                <path className="fill-arrow" id="fi-rr-arrow-small-right" d="M19.376,6.518a1.77,1.77,0,0,0,0,2.5L25.2,14.851,6.77,14.863a1.77,1.77,0,1,0,0,3.54L25.2,18.391,19.376,24.22a1.77,1.77,0,1,0,2.5,2.5l6.348-6.346a5.311,5.311,0,0,0,0-7.511L21.879,6.518A1.77,1.77,0,0,0,19.376,6.518Z" transform="translate(7.391 8.596)" fill="#fff" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            <Api options={apiOptionsGallery} />
                        </div>
                    </Popup>
                </>
            )}

        </Layout >
    )
}