import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Button from "./Button";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import GlobalState from "../GlobalState";

export default function Layout(props) {

    const [burgerMenu, setBurgerMenu] = useState(false);
    const { generalData, instaFeed } = useContext(GlobalState);
    // useEffect(() => {
    //     if (instaFeed) {
    //     }
    // }, [instaFeed])
    useEffect(() => {
        document.querySelector('body').style.overflow = burgerMenu ? 'hidden' : null;
        document.querySelector('html').style.overflow = burgerMenu ? 'hidden' : null;
        document.getElementById('root').style.overflow = burgerMenu ? 'hidden' : null;
    }, [burgerMenu]);

    return (
        <>

            {
                generalData ?
                    <>
                        <div className="position-relative">
                            {
                                <>
                                    {
                                        (props.rightPinkBg === true) ?
                                            <img className="pink-right-bg d-lg-block d-none" src="/assets/images/fixed/header-right.svg" alt="background" />
                                            :
                                            null
                                    }

                                    {
                                        (props.activePage === "contact") ?
                                            <img className="pink-right-bg contact d-lg-block d-none" src="/assets/images/fixed/header-right.svg" alt="background" />
                                            :
                                            null
                                    }

                                    {
                                        (props.pinkFlower === true) ?
                                            <img className="header-right d-lg-block d-none" src="/assets/images/fixed/pink-flower.svg" alt="logo" />
                                            :
                                            <img className="header-right d-lg-block d-none" src="/assets/images/fixed/purple-flower.svg" alt="logo" />
                                    }
                                </>
                            }
                            <div className="container-fluid">
                                <div className="navbar-padding">
                                    <div className="row align-items-center py-lg-3 py-4">
                                        <div className="col-lg-3 col-md-3 col">
                                            <div className="position-relative">
                                                <img className="header-left d-lg-block d-md-block d-none" src="/assets/images/fixed/header-left.svg" alt="left-background" />
                                                <Link className="menu-link d-lg-block d-md-block d-none" to="/">
                                                    <div className="logo-header d-md-block d-none">
                                                        <img src="/assets/images/fixed/white-logo.svg" alt="logo" />
                                                    </div>
                                                </Link>
                                                <Link className="menu-link " to="/">
                                                    <div className="logo-header d-lg-none d-md-none d-block">
                                                        <img src="/assets/images/fixed/black-logo.svg" alt="logo" />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="col align-items-center justify-content-center d-lg-flex d-none">
                                            <div className="mx-3">
                                                <Link className={"menu-link" + (props.activePage === "home" ? " active" : "")} to="/">{generalData.headerTitles.home_menu_label}</Link>
                                            </div>
                                            <div className="mx-3">
                                                <Link className={"menu-link" + (props.activePage === "about" ? " active" : "")} to="/about">{generalData.headerTitles.about_menu_label}</Link>
                                            </div>
                                            <div className="mx-3">
                                                <Link className={"menu-link" + (props.activePage === "services" ? " active" : "")} to="/services">{generalData.headerTitles.services_menu_label}</Link>
                                            </div>
                                            <div className="mx-3">
                                                <Link className={"menu-link" + (props.activePage === "gallery" ? " active" : "")} to="/gallery">{generalData.headerTitles.gallery_menu_label}</Link>
                                            </div>
                                            <div className="mx-3">
                                                <Link className={"menu-link" + (props.activePage === "contact" ? " active" : "")} to="/contact">{generalData.headerTitles.contact_us_menu_label}</Link>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-3 justify-content-end d-lg-flex d-none">
                                            {/* <div className="button-arrow position-relative">
                                                <Link className="menu-link" to={generalData.headerTitles.button_url}>
                                                    <Button
                                                        buttonClass="button-link header-button mb-0"
                                                        buttonColor="button header-button grey-button shadow"
                                                        label={generalData.headerTitles.button_label}
                                                    />
                                                </Link>
                                            </div> */}
                                        </div>
                                        <div className="col align-items-center d-lg-none d-flex justify-content-end" onClick={() => setBurgerMenu(true)}>
                                            <img className="burger-menu" src="/assets/images/fixed/menu-burger.svg" alt="burger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="padding-under-footer">
                            {props.children}
                        </div>

                        <div className="footer py-sm-5 py-4 position-relative">
                            <img className="black-fleur" src="/assets/images/fixed/black-flower.svg" alt="flower" />
                            <img className="footer-bg" src="/assets/images/fixed/leaf-footer.svg" alt="flower" />
                            <div className="container-fluid">
                                <div className="footer-padding">
                                    <div className="row justify-content-lg-between">
                                        <div className="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2 px-sm-2 padding-0">
                                            <h2 className="mb-3 mt-sm-0 mt-4 text-sm-start text-center insta">{generalData.footerTitles.instagram_title}</h2>
                                            <Swiper
                                                slidesPerView={2}
                                                spaceBetween={10}
                                                centeredSlides={true}
                                                loop={true}
                                                allowTouchMove={true}
                                                className="mySwiper"
                                                breakpoints={{

                                                    1399.98: {
                                                        slidesPerView: 3,
                                                        allowTouchMove: false,
                                                    },

                                                    991.98: {
                                                        slidesPerView: 2,
                                                        allowTouchMove: true,
                                                    },

                                                    // when window width is >= 767.98px
                                                    767.98: {
                                                        slidesPerView: 2,
                                                        allowTouchMove: true
                                                    },

                                                    575.98: {
                                                        slidesPerView: 1.9,
                                                        allowTouchMove: true
                                                    },

                                                    380: {
                                                        slidesPerView: 3,
                                                        allowTouchMove: false
                                                    }

                                                }}
                                            >
                                                {
                                                    instaFeed?.length ?
                                                        instaFeed?.map((el, index) => (
                                                            index < 3 ?
                                                                <SwiperSlide key={index}>
                                                                    <div className="insta">
                                                                        <a href={el.permalink} target="_blank" rel="noreferrer">
                                                                            <div className="ratio insta-feed">

                                                                                <img src={el.thumbnail_url ? el.thumbnail_url : el.media_url} alt="insta-feed" />
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </SwiperSlide>
                                                                : null
                                                        ))
                                                        : null

                                                }


                                            </Swiper>
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-sm-6 align-self-end order-sm-2 order-1">
                                            <div className="row pb-sm-4 text-lg-start text-lg-start text-center justify-content-lg-start  justify-content-center">
                                                <div className="col-lg col-md col-sm-4 col-5 d-block order-md-1 order-2">
                                                    <div className="my-sm-3 my-4">
                                                        <Link className={"footer-link" + (props.activePage === "about" ? " active" : "")} to="/about">{generalData.footerTitles.about_label}</Link>
                                                    </div>
                                                    <div className="my-sm-3 my-4">
                                                        <Link className={"footer-link" + (props.activePage === "services" ? " active" : "")} to="/services">{generalData.footerTitles.services_label}</Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg col-md col-sm-4 col-5 d-block order-md-2 order-3">
                                                    <div className="my-sm-3 my-4">
                                                        <Link className={"footer-link" + (props.activePage === "gallery" ? " active" : "")} to="/gallery">{generalData.footerTitles.gallery_label}</Link>
                                                    </div>
                                                    <div className="my-sm-3 my-4">
                                                        <Link className={"footer-link" + (props.activePage === "contact" ? " active" : "")} to="/contact">{generalData.footerTitles.contact_us_label}</Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1 col-sm-12 text-lg-end text-md-start text-center order-md-3 order-1">
                                                    <Link className="menu-link" to="/">
                                                        <div className="logo-header">
                                                            <img className="logo-footer" src="/assets/images/fixed/logo.svg" alt="logo" />
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="social-icon d-flex justify-content-lg-start justify-content-center text-md-start text-center">
                                                    {
                                                        generalData.socialChannels.map((socialChannel, index) =>
                                                            <div key={index}>
                                                                <a href={socialChannel.is_mail === 1 ? "mailto:" + socialChannel.url : socialChannel.url} target="_blank" rel="noreferrer">
                                                                    <img className="me-md-3 mx-2" src={process.env.REACT_APP_BASE_URL + socialChannel.icon_white} alt="icon" />
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-sm-start text-center">
                                        <a href={generalData.footerTitles.instagram_url} target="_blank" rel="noreferrer" className="mt-3 visit">{generalData.footerTitles.instagram_button_label}</a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"mobile-menu" + (burgerMenu ? " " : " hide")}>
                            <div className="row justify-content-end h-100">
                                <div className="col-md-5 col-sm-7 col-9">
                                    <div className="row background-mobile-menu h-100 w-100">
                                        <div className="col d-flex flex-column h-100 justify-content-center">
                                            <img className="close-menu" src="/assets/images/fixed/close.svg" alt="close" onClick={() => setBurgerMenu(false)} />
                                            <div className="row justify-content-center py-5">
                                                <div className="col-md-11 col-sm-10 col-12 px-sm-3 px-3">
                                                    <div className="pb-4">
                                                        <Link className={"menu-link mobile" + (props.activePage === "home" ? " active" : "")} to="/">{generalData.headerTitles.home_menu_label}</Link>
                                                    </div>
                                                    <div className="pb-4">
                                                        <Link className={"menu-link mobile" + (props.activePage === "about" ? " active" : "")} to="/about">{generalData.headerTitles.about_menu_label}</Link>
                                                    </div>
                                                    <div className="pb-4">
                                                        <Link className={"menu-link mobile" + (props.activePage === "services" ? " active" : "")} to="/services">{generalData.headerTitles.services_menu_label}</Link>
                                                    </div>
                                                    <div className="pb-4">
                                                        <Link className={"menu-link mobile" + (props.activePage === "gallery" ? " active" : "")} to="/gallery">{generalData.headerTitles.gallery_menu_label}</Link>
                                                    </div>
                                                    <div className="pb-4">
                                                        <Link className={"menu-link mobile" + (props.activePage === "contact" ? " active" : "")} to="/contact">{generalData.headerTitles.contact_us_menu_label}</Link>
                                                    </div>
                                                    <div className="social-icon pb-4 d-flex">
                                                        {
                                                            generalData.socialChannels.map((socialChannel, index) =>
                                                                <div key={index}>
                                                                    <a href={socialChannel.is_mail === 1 ? "mailto:" + socialChannel.url : socialChannel.url} target="_blank" rel="noreferrer">
                                                                        <img className="me-3" src={process.env.REACT_APP_BASE_URL + socialChannel.icon_white} alt="icon" />
                                                                    </a>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    {/* <div className="button-arrow position-relative">
                                                        <Link className="menu-link" to={generalData.headerTitles.button_url}>
                                                            <Button
                                                                buttonClass="button-link mobile mb-0"
                                                                buttonColor="button pink-button mobile shadow"
                                                                label={generalData.headerTitles.button_label}
                                                            />
                                                        </Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
            }
        </>
    )
}