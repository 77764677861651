export default function Button(props) {
    return (
        <div>
            <button className={props.buttonColor}>
                <p className={props.buttonClass}>
                    {props.label}
                </p>
            </button>
            <img className="arrow-button" src="/assets/images/fixed/arrow-button.svg" alt="arrow" />
        </div>
    )
}