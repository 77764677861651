export default function Service(props) {
    return (
        <div className="service">
            <div className="row justify-content-center">
                <div className="col-sm-8 col-9">
                    <div className="position-relative">
                        <div className="ratio ratio-1x1">
                            <div className="circle shadow">
                                <img className="service-icon" src={props.icon} alt={props.title} />
                                <img className="service-icon-hover" src={props.iconHover} alt={props.title} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <h3 className="mt-3 mb-0">{props.title}</h3>
                <p className="mt-3">{props.subtitle}</p>
            </div>
        </div>
    )
}