export default function SwiperProduct(props) {
    return (
        <div className="product-slider text-center">
            <div className="position-relative">
                <div className="ratio product-image">
                    <img src={props.image} alt="product" />
                </div>
                <img className="view-icon" src="/assets/images/fixed/view-icon.svg" alt="icon" />
            </div>
            <h3 className="mt-3 mb-3">{props.title}</h3>
            <h4 className="mb-0">{props.subtitle}</h4>
        </div>
    )
}