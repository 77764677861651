import Button from "../components/Button";
import Layout from "../components/Layout";
import Service from "../components/Service";
import WhatWeDo from "../components/WhatWeDo";
import MeetFounder from "../components/MeetFounder";
import Testimonial from "../components/Testimonial";
import CurlAnimated from "../components/CurlAnimated";
import Popup from "../components/Popup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import SwiperCore, { Autoplay } from 'swiper';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import GlobalState from "../GlobalState";
import GalleryPopup from "../components/GalleryPopup";
import { Helmet } from "react-helmet";

export default function Home() {
    SwiperCore.use([Autoplay])

    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
    const [calendlyOpen, setCalendlyOpen] = useState(false)

    useEffect(() => {
        document.querySelector('body').style.overflow = calendlyOpen ? 'hidden' : null;
        document.querySelector('html').style.overflow = calendlyOpen ? 'hidden' : null;
        document.getElementById('root').style.overflow = calendlyOpen ? 'hidden' : null;
    }, [calendlyOpen]);

    const { generalData, servicesContent, seoTags } = useContext(GlobalState);
    const [galleryOpen, setGalleryOpen] = useState(-1);
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        window.triggerScroll();
    });

    function handleClickOutside(event) {
        if (!event.target.closest('.gallery')) {
            setGalleryOpen(-1)
        }
    }

    function handleClickOutsideCalendly(event) {
        if (!event.target.closest('.calendly')) {
            setCalendlyOpen(false)
        }
    }

    useEffect(() => {
        if ((swiper) && (swiper.slideTo) && (galleryOpen) !== -1) {
            swiper.slideTo(galleryOpen, 0);
        }
    }, [galleryOpen, swiper]);

    return (
        <Layout activePage="home" pinkFlower={true}>
            {seoTags ?
                <Helmet>
                    <title>{seoTags[0].title}</title>
                    <meta name="title" content={seoTags[0].title} />
                    <meta name="description" content={seoTags[0].description} />

                    <meta property="og:title" content={seoTags[0].title} />
                    <meta property="og:description" content={seoTags[0].description} />
                    <meta property="og:image" content={seoTags[0].image} />

                    <meta property="twitter:title" content={seoTags[0].title} />
                    <meta property="twitter:description" content={seoTags[0].description} />
                    <meta property="twitter:image" content={seoTags[0].image} />
                </Helmet>
                :
                null
            }

            {
                generalData ?
                    <>
                        <div className="position-relative home-banner">
                            <img className="right-home-banner" src={process.env.REACT_APP_BASE_URL + generalData.homeSettings.banner_image_right} alt="banner" animate="bottom-animate" />
                            <div className="pink-box-right" animate="right-animate"></div>
                            <div className="home-curl-animated">
                                <CurlAnimated />
                            </div>

                            <img className="left-home-banner" src={process.env.REACT_APP_BASE_URL + generalData.homeSettings.banner_image_left} alt="banner" animate="left-animate" />
                            <div className="pink-box-left" animate="top-animate"></div>
                            <div>
                                <img className="dots-home" src="/assets/images/fixed/dots-home.svg" alt="dots" />
                            </div>

                            <div className="container position-relative text-position">
                                <div className="row text-banner">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-7 col-md-9 col-sm-8 col-6 text-end">
                                        <h1 className="title-banner mb-lg-5 mb-md-5 mb-sm-5 mb-0" animate="left-animate" >{generalData.homeSettings.banner_title}</h1>
                                        <div className=" d-sm-inline-flex d-none" animate="bottom-animate">
                                            <div className="button-arrow position-relative" onClick={() => setCalendlyOpen(true)}>
                                                <div className="menu-link">
                                                    <Button
                                                        buttonClass="button-link add-space mb-0"
                                                        buttonColor="button grey-button shadow"
                                                        label={generalData.homeSettings.banner_button_url}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" d-inline-flex d-md-none d-sm-none w-100 justify-content-center pt-5" animate="top-animate">
                            <div className="button-arrow position-relative" onClick={() => setCalendlyOpen(true)}>
                                <div className="menu-link">
                                    <Button
                                        buttonClass="button-link add-space mb-0"
                                        buttonColor="button grey-button shadow"
                                        label={generalData.homeSettings.banner_button_url}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="pb-lg-5">
                            <div className="container padding-section " >
                                <WhatWeDo
                                    label={generalData.homeSettings.what_we_do_button_label}
                                    button={true}
                                    column1="col-lg-4 order-lg-1 order-2 pt-lg-0 mt-lg-0 pt-md-5 mt-md-3 pt-sm-5 mt-sm-5 pt-4 mt-0"
                                    column2="col-lg-6 order-lg-2 order-1 pb-lg-0 pb-sm-5 pb-md-0 pb-5"
                                    class="row justify-content-between align-items-center"
                                    title={generalData.homeSettings.what_we_do_title}
                                    subtitle={generalData.homeSettings.what_we_do_description}
                                    firstRatio="ratio what-we-do what-we-do-first-image"
                                    firstImage={process.env.REACT_APP_BASE_URL + generalData.homeSettings.what_we_do_image_one}
                                    secondRatio="ratio what-we-do what-we-do-second-image"
                                    secondImage={process.env.REACT_APP_BASE_URL + generalData.homeSettings.what_we_do_image_two}
                                    dots="dots-home-section-2"
                                    flower="flower-home"
                                />
                            </div>
                        </div>

                        <div className="padding-section-bottom position-relative">
                            <div className="curly">
                                <CurlAnimated
                                />
                            </div>
                            <MeetFounder
                                image={process.env.REACT_APP_BASE_URL + generalData.homeSettings.meet_the_founder_image}
                                title={generalData.homeSettings.meet_the_founder_title}
                                subtitle={generalData.homeSettings.meet_the_founder_description}
                            />
                        </div>

                        <div className="container padding-section-bottom" >
                            <div className="row align-items-center justify-content-between images-mission">
                                <div className="col-lg-3 col-6 pb-sm-0 pb-4 mission-col " animate="left">
                                    <div className="position-relative">
                                        <div className="ratio mission-ratio-top">
                                            <img src={process.env.REACT_APP_BASE_URL + generalData.homeSettings.our_mission_image_before} alt="imge" />
                                            <div className="overlay-rounded-top"></div>
                                        </div>
                                        <p className="text-on-image-bottom">{generalData.homeSettings.translations[0].our_mission_before}</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6 mission-col" animate="left">
                                    <div className="position-relative">
                                        <div className="ratio mission-ratio-bottom">
                                            <img src={process.env.REACT_APP_BASE_URL + generalData.homeSettings.our_mission_image_after} alt="imge" />
                                            <div className="overlay-rounded-bottom"></div>
                                        </div>
                                        <p className="text-on-image-top mt-3">{generalData.homeSettings.translations[0].our_mission_after}</p>
                                    </div>
                                </div>
                                <div className="col-lg-1 d-md-none d-none"></div>
                                <div className="col-lg-5 col-md-12 pt-md-4 pt-sm-4 pt-4" animate="right">
                                    <h2 className="main-title pink mb-3">{generalData.homeSettings.our_mission_title}</h2>
                                    <p className="mb-5">{generalData.homeSettings.our_mission_description}</p>
                                    <div className="button-arrow position-relative">
                                        <Link className="menu-link" to="/about">
                                            <Button
                                                buttonClass="button-link add-space mb-0"
                                                buttonColor="button pink-button shadow"
                                                label={generalData.homeSettings.our_mission_button_label}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="position-relative" >
                            <div className="curl-between-section">
                                <CurlAnimated
                                />
                            </div>
                            <div className="padding-section-bottom" >
                                <div className="service-background">
                                    <div className="container padding-section-bottom py-5">
                                        <div className="py-lg-5">
                                            <h2 className="main-title black text-center mb-5" animate="up">{generalData.homeSettings.services_title}</h2>
                                            <div className="position-relative px-5" animate="down">
                                                <Swiper
                                                    navigation={{
                                                        nextEl: '.swiper-button-next',
                                                        prevEl: '.swiper-button-prev'
                                                    }}
                                                    autoplay={{
                                                        delay: 3000,
                                                    }}
                                                    loop={true}
                                                    centeredSlides={true}
                                                    modules={[Navigation]}
                                                    slidesPerView={1}
                                                    spaceBetween={20}
                                                    className="service-swiper"
                                                    breakpoints={{

                                                        1399.98: {
                                                            slidesPerView: 3,
                                                            spaceBetween: 140
                                                        },

                                                        991.98: {
                                                            slidesPerView: 3,
                                                            spaceBetween: 80
                                                        },

                                                        // when window width is >= 767.98px
                                                        767.98: {
                                                            slidesPerView: 2,
                                                            spaceBetween: 20,
                                                            centeredSlides: false
                                                        },

                                                        575.98: {
                                                            slidesPerView: 1.5,
                                                            spaceBetween: 80
                                                        },

                                                        400: {
                                                            slidesPerView: 1.2,
                                                            spaceBetween: 80
                                                        },
                                                    }}
                                                >
                                                    {
                                                        servicesContent ?
                                                            servicesContent.services.map((service, index) => (
                                                                <SwiperSlide key={index}>
                                                                    <Link to={"/services/section-service-" + service.id} >
                                                                        <Service
                                                                            title={service.title}
                                                                            subtitle={service.description}
                                                                            icon={process.env.REACT_APP_BASE_URL + service.icon}
                                                                            iconHover={process.env.REACT_APP_BASE_URL + service.icon_hover}
                                                                        />
                                                                    </Link>
                                                                </SwiperSlide>
                                                            ))
                                                            :
                                                            null
                                                    }
                                                </Swiper>
                                                <div className="swiper-button-prev custom-arrows">
                                                    <svg id="Component_15_7" data-name="Component 15 – 7" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                                        <g className="stroke-arrow" id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#60584d" strokeWidth="2">
                                                            <circle cx="25" cy="25" r="25" stroke="none" />
                                                            <circle cx="25" cy="25" r="24" fill="none" />
                                                        </g>
                                                        <path className="stroke-fill" id="fi-rr-arrow-small-right" d="M15.407,6.518a1.77,1.77,0,0,1,0,2.5L9.579,14.851l18.433.012a1.77,1.77,0,0,1,0,3.54L9.577,18.391l5.829,5.829a1.77,1.77,0,1,1-2.5,2.5L6.556,20.377a5.311,5.311,0,0,1,0-7.511L12.9,6.518A1.77,1.77,0,0,1,15.407,6.518Z" transform="translate(7.391 8.596)" fill="#60584d" />
                                                    </svg>
                                                </div>
                                                <div className="swiper-button-next custom-arrows">
                                                    <svg id="Component_15_6" data-name="Component 15 – 6" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                                        <g className="stroke-arrow" id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#60584d" strokeWidth="2">
                                                            <circle cx="25" cy="25" r="25" stroke="none" />
                                                            <circle cx="25" cy="25" r="24" fill="none" />
                                                        </g>
                                                        <path className="stroke-fill" id="fi-rr-arrow-small-right" d="M19.376,6.518a1.77,1.77,0,0,0,0,2.5L25.2,14.851,6.77,14.863a1.77,1.77,0,1,0,0,3.54L25.2,18.391,19.376,24.22a1.77,1.77,0,1,0,2.5,2.5l6.348-6.346a5.311,5.311,0,0,0,0-7.511L21.879,6.518A1.77,1.77,0,0,0,19.376,6.518Z" transform="translate(7.391 8.596)" fill="#60584d" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center justify-content-center d-flex pt-5">
                                    <div className="button-arrow position-relative">
                                        <div className="menu-link" onClick={() => setCalendlyOpen(true)}>
                                            <Button
                                                buttonClass="button-link add-space mb-0"
                                                buttonColor="button pink-button shadow"
                                                label={generalData.homeSettings.services_button_label}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-lg padding-section-bottom" animate="left">
                                <div className="text-center" animate="up">
                                    <h2 className="main-title pink mb-3">{generalData.homeSettings.testimonials_title}</h2>
                                </div>
                                <div className="">
                                    <Swiper
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: true,
                                        }}
                                        centeredSlides={true}
                                        slidesPerView={1.2}
                                        loop={true}
                                        spaceBetween={10}
                                        pagination={{
                                            clickable: true,
                                            dynamicBullets: true,

                                        }}
                                        modules={[Pagination]}
                                        className="testimonials-swiper px-lg-3 px-md-3 px-0"
                                        breakpoints={{
                                            1399.98: {
                                                slidesPerView: 3,
                                                spaceBetween: 30,
                                            },

                                            991.98: {
                                                slidesPerView: 3,
                                                spaceBetween: 30,
                                            },
                                            // when window width is >= 767.98px
                                            767.98: {
                                                slidesPerView: 2,
                                                spaceBetween: 10
                                            },

                                            575.98: {
                                                slidesPerView: 2,
                                                spaceBetween: 0
                                            },

                                            400: {
                                                slidesPerView: 1.5,
                                                spaceBetween: 0
                                            }
                                        }}>
                                        {
                                            generalData.testimonials.map((testimonial, index) => (
                                                <SwiperSlide className="h-auto" key={index}>
                                                    <Testimonial
                                                        image={process.env.REACT_APP_BASE_URL + testimonial.image}
                                                        name={testimonial.name}
                                                        text={testimonial.description}
                                                    />
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                            </div>
                        </div>

                        <div className="container padding-section-bottom" >
                            <div className="text-center">
                                <h2 className="main-title pink mb-3" animate="left">{generalData.homeSettings.gallery_title}</h2>
                            </div>
                            {generalData.galleryItems ?
                                <div className=" position-relative d-md-block d-none">
                                    <div className="row justify-content-center align-items-center ">
                                        <div className="col-lg-4 col-md-4 px-lg-3 px-1" animate="left">
                                            {!generalData.galleryItems[0] ? null :
                                                <div className="pb-5 position-relative gallery-hover" index="0" onClick={() => setGalleryOpen(0)} >
                                                    <div className="ratio first-gallery-image">
                                                        <img src={process.env.REACT_APP_BASE_URL + generalData.galleryItems[0].image} alt="gallery" />
                                                        <div className="border-left-on-hover"></div>
                                                        <div className="overlay-gallery"></div>
                                                    </div>
                                                    <img className="view-hover" src="/assets/images/fixed/view.svg" alt="view" />
                                                </div>
                                            }
                                            {!generalData.galleryItems[2] ? null :
                                                <div className="pt-5 position-relative gallery-hover" index="2" onClick={() => setGalleryOpen(2)} >
                                                    <div className="ratio third-gallery-image">
                                                        <img src={process.env.REACT_APP_BASE_URL + generalData.galleryItems[2].image} alt="gallery" />
                                                        <div className="border-right-on-hover"></div>
                                                        <div className="overlay-gallery"></div>
                                                    </div>
                                                    <img className="view-hover" src="/assets/images/fixed/view.svg" alt="view" />
                                                </div>
                                            }
                                        </div>
                                        {generalData.galleryItems[0] && !generalData.galleryItems[1] && !generalData.galleryItems[2] && !generalData.galleryItems[3] && !generalData.galleryItems[4] ? " " :
                                            <div className="col-lg-4 col-md-4 px-lg-5 px-3" animate="left">
                                                {!generalData.galleryItems[4] ? null :
                                                    <div className="position-relative gallery-hover" index="4" onClick={() => setGalleryOpen(4)}>
                                                        <div className="ratio fifth-gallery-image">
                                                            <img src={process.env.REACT_APP_BASE_URL + generalData.galleryItems[4].image} alt="gallery" />
                                                            <div className="border-last-on-hover"></div>
                                                            <div className="overlay-gallery middle"></div>
                                                        </div>
                                                        <img className="view-hover" src="/assets/images/fixed/view.svg" alt="view" />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {generalData.galleryItems[0] && !generalData.galleryItems[1] && !generalData.galleryItems[2] && !generalData.galleryItems[3] && !generalData.galleryItems[4] ? " " :
                                            <div className="col-lg-4 col-md-4 px-lg-3 px-1" animate="left">
                                                {!generalData.galleryItems[1] ? null :
                                                    <div className="pb-5 position-relative gallery-hover" index="1" onClick={() => setGalleryOpen(1)}>
                                                        <div className="ratio second-gallery-image">
                                                            <img src={process.env.REACT_APP_BASE_URL + generalData.galleryItems[1].image} alt="gallery" />
                                                            <div className="border-left-on-hover"></div>
                                                            <div className="overlay-gallery"></div>
                                                        </div>
                                                        <img className="view-hover" src="/assets/images/fixed/view.svg" alt="view" />
                                                    </div>
                                                }


                                                <div className="pt-5">
                                                    {!generalData.galleryItems[3] ? null :
                                                        <div className="position-relative gallery-hover" index="3" onClick={() => setGalleryOpen(3)}>
                                                            <div className="ratio fourth-gallery-image">
                                                                <img src={process.env.REACT_APP_BASE_URL + generalData.galleryItems[3].image} alt="gallery" />
                                                                <div className="border-right-on-hover"></div>
                                                                <div className="overlay-gallery"></div>
                                                            </div>
                                                            <img className="view-hover" src="/assets/images/fixed/view.svg" alt="view" />
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                null
                            }

                            <div className="d-md-none d-block">
                                <div className="position-relative">
                                    <Swiper
                                        centeredSlides={true}
                                        slidesPerView={1}
                                        loop={true}
                                        spaceBetween={50}
                                        pagination={{
                                            clickable: true,
                                            dynamicBullets: true,

                                        }}
                                        modules={[Pagination]}
                                        className="gallery"
                                        breakpoints={{

                                            // when window width is >= 767.98px
                                            767.98: {
                                                slidesPerView: 2,
                                                spaceBetween: 30,
                                                allowTouchMove: true,
                                                navigation: true,
                                                centeredSlides: true,
                                                // spaceBetween: 100,
                                            },

                                            575.98: {
                                                slidesPerView: 2,
                                                spaceBetween: 50
                                            },

                                            400: {
                                                slidesPerView: 2,
                                            }
                                        }}>
                                        {
                                            generalData.galleryItems.map((galleryItem, index) =>
                                                <SwiperSlide key={index} onClick={() => setGalleryOpen(index)}>
                                                    <div className="position-relative pt-3 ms-3" >
                                                        <div className="ratio gallery-mobile">
                                                            <img src={process.env.REACT_APP_BASE_URL + galleryItem.image} alt="gallery" />
                                                            <div className="border-on-image-gallery"></div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }
                                    </Swiper>
                                </div>
                            </div>

                            <div className="justify-content-center d-flex pt-5">
                                <div className="button-arrow position-relative">
                                    <Link className="menu-link" to="/gallery">
                                        <Button
                                            buttonClass="button-link add-space mb-0"
                                            buttonColor="button pink-button shadow"
                                            label={generalData.homeSettings.gallery_button_label}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* <div className="padding-section-bottom" >
                            <div className="position-relative">
                                <div className="curl-between-section-gallery">
                                    <img src="/assets/images/fixed/small-curl.svg" alt="curl" />
                                </div>
                                <div className="shop-now">
                                    <img className="shadow" src={process.env.REACT_APP_BASE_URL + generalData.homeSettings.our_products_image} alt="shop-now" />
                                    <div className="overlay-shop"></div>
                                </div>
                                <div className="container">
                                    <div className="row height-products">
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-6 col-6 text-end" animate="left">
                                            <h2 className="main-title black title-product mb-0 pt-5">{generalData.homeSettings.our_products_title}</h2>
                                            <div className="pt-sm-5 pt-3">
                                                <div className="button-arrow position-relative ">
                                                    <Link className="menu-link" to="/products">
                                                        <Button
                                                            buttonClass="button-link mb-0"
                                                            buttonColor="button grey-button mobile shadow"
                                                            label={generalData.homeSettings.our_products_button_label}
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}


                        <Popup
                            isOpen={galleryOpen !== -1}
                            closePopup=" close-popup text-end"
                            close={() => setGalleryOpen(-1)}
                            whiteButton={true}
                            modalWindowClass="col-lg-7 col-md-8 col-sm-9 col-9"
                            handleClickOutside={(e) => handleClickOutside(e)}
                        >
                            {galleryOpen !== -1 ?

                                <div className="position-relative gallery">
                                    <Swiper onSwiper={setSwiper} modules={[Navigation]} className="gallery-popup" spaceBetween={20} navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev'
                                    }}>
                                        {
                                            generalData.galleryItems.map((galleryItem, index) =>
                                                <SwiperSlide key={index}>
                                                    <GalleryPopup
                                                        image={process.env.REACT_APP_BASE_URL + galleryItem.image}
                                                    />
                                                </SwiperSlide>
                                            )
                                        }
                                    </Swiper>

                                    <div>
                                        <div className="swiper-button-prev gallery-swiper">
                                            <svg id="Component_15_9" data-name="Component 15 – 9" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                                <g className="gallery-arrow" id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#fff" strokeWidth="2">
                                                    <circle cx="25" cy="25" r="25" stroke="none" />
                                                    <circle cx="25" cy="25" r="24" fill="none" />
                                                </g>
                                                <path className="fill-arrow" id="fi-rr-arrow-small-right" d="M15.407,6.518a1.77,1.77,0,0,1,0,2.5L9.579,14.851l18.433.012a1.77,1.77,0,0,1,0,3.54L9.577,18.391l5.829,5.829a1.77,1.77,0,1,1-2.5,2.5L6.556,20.377a5.311,5.311,0,0,1,0-7.511L12.9,6.518A1.77,1.77,0,0,1,15.407,6.518Z" transform="translate(7.391 8.596)" fill="#fff" />
                                            </svg>
                                        </div>
                                        <div className="swiper-button-next gallery-swiper">
                                            <svg id="Component_15_8" data-name="Component 15 – 8" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                                <g className="gallery-arrow" id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#fff" strokeWidth="2">
                                                    <circle cx="25" cy="25" r="25" stroke="none" />
                                                    <circle cx="25" cy="25" r="24" fill="none" />
                                                </g>
                                                <path className="fill-arrow" id="fi-rr-arrow-small-right" d="M19.376,6.518a1.77,1.77,0,0,0,0,2.5L25.2,14.851,6.77,14.863a1.77,1.77,0,1,0,0,3.54L25.2,18.391,19.376,24.22a1.77,1.77,0,1,0,2.5,2.5l6.348-6.346a5.311,5.311,0,0,0,0-7.511L21.879,6.518A1.77,1.77,0,0,0,19.376,6.518Z" transform="translate(7.391 8.596)" fill="#fff" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </Popup>
                    </>
                    :
                    null
            }
            <div className={"popup text-center" + (calendlyOpen ? "" : " fade-out")} onClick={(e) => handleClickOutsideCalendly(e)}>
                <div className="close-popup-calendly" onClick={() => setCalendlyOpen(false)}>
                    <svg className="close-pink" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <path className="pink-fill" id="close" d="M11.832,10.136,19.62,2.348A1.3,1.3,0,0,0,17.788.516L10,8.3,2.212.516A1.3,1.3,0,0,0,.38,2.348l7.788,7.788L.38,17.924a1.3,1.3,0,1,0,1.832,1.832L10,11.968l7.788,7.788a1.3,1.3,0,0,0,1.832-1.832Zm0,0" transform="translate(0 -0.136)" fill="#fff" />
                    </svg>
                </div>
                <div className="calendly-inline-widget" data-url="https://calendly.com/curlsbyfida/curly-hair-cut" style={{ minWidth: 320, height: 630 }} />
            </div>

        </Layout >

    )
}