import { useEffect } from "react"

export default function Popup(props) {

    useEffect(() => {
        document.querySelector('body').style.overflow = props.isOpen ? 'hidden' : null;
        document.querySelector('html').style.overflow = props.isOpen ? 'hidden' : null;
        document.getElementById('root').style.overflow = props.isOpen ? 'hidden' : null;
    }, [props.isOpen]);

    return (
        <div className={"popup" + (props.isOpen ? " " : " fade-out")} onClick={(e) => props.handleClickOutside(e)}>
            <div className="row h-100 align-items-center justify-content-center">
                <div className={props.modalWindowClass}>
                    <div className={props.positionRelative}>
                        <div className={props.closePopup} onClick={props.close}>
                            {
                                props.blackButton === true ?
                                    <svg className="close-black" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                        <path className="black-fill" id="close" d="M8.874,7.636l5.841-5.841A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.795L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0" transform="translate(0 -0.136)" fill="#60584d" />
                                    </svg>
                                    :
                                    null
                            }
                            {
                                props.whiteButton === true ?
                                    <svg className="close-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <path className="white-fill" id="close" d="M11.832,10.136,19.62,2.348A1.3,1.3,0,0,0,17.788.516L10,8.3,2.212.516A1.3,1.3,0,0,0,.38,2.348l7.788,7.788L.38,17.924a1.3,1.3,0,1,0,1.832,1.832L10,11.968l7.788,7.788a1.3,1.3,0,0,0,1.832-1.832Zm0,0" transform="translate(0 -0.136)" fill="#fff" />
                                    </svg>
                                    :
                                    null
                            }


                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}