export default function MeetFounder(props) {
    return (

        <div className="container position-relative">
            {
                props.curly === true ?
                    <img className="curly" src={props.curly} alt="curly" />
                    :
                    null
            }
            <div className="row align-items-end justify-content-center">
                <div className="col-lg-3 col-md-6 col-sm-8 col-10 pe-lg-0 ps-lg-0 position-relative" animate="left" style={{zIndex: 10}}>
                    <div className="ratio founder-ratio">
                        <img className="shadow" src={props.image} alt={props.title} />
                        <div className="border-on-image"></div>
                    </div>
                </div>
                <div className="col-lg-9 col-md-12 position-relative ps-lg-0" animate="right">
                    <img className="black-flower" src="/assets/images/fixed/black-fleur.svg" alt="" />
                    <div className="text-md-start text-center py-5 ms-lg-5">
                        <h2 className="main-title white mb-3 ">{props.title}</h2>
                        <p className="mb-0 ">{props.subtitle}</p>
                        <div dangerouslySetInnerHTML={{ __html: props.text }} />
                    </div>
                    <div className="founder-background "></div>
                </div>
            </div>
        </div>
    )
}