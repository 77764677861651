export default function Testimonial(props) {
    return (
        <div className="position-relative h-100">
            <div className="swiper-testimonial shadow p-4 position-raltive">
                <div className="text-center ">
                    <div className="mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="30" viewBox="0 0 45 30">
                            <g id="quote" transform="translate(45 30) rotate(180)">
                                <path className="fill-quote" id="Path_6621" data-name="Path 6621" d="M20.59,10.668A20.834,20.834,0,0,1,17.228,22,19.419,19.419,0,0,1,7.4,29.385c-.462.162-.966.325-1.429.447a2.151,2.151,0,0,1-2.437-.812A2.022,2.022,0,0,1,3.657,26.5c.63-.731,1.3-1.5,1.891-2.274a21.491,21.491,0,0,0,2.521-4.344c.126-.244.126-.365-.21-.447A9.885,9.885,0,0,1,0,9.775,10.046,10.046,0,0,1,8.321.194a10.289,10.289,0,0,1,12.1,8.161A9.568,9.568,0,0,1,20.59,10.668Z" transform="translate(24.395 0.025)" fill="#fff" />
                                <path className="fill-quote" id="Path_6622" data-name="Path 6622" d="M20.6,10.247a22.581,22.581,0,0,1-2.059,9.5,18.937,18.937,0,0,1-10.462,9.42,18.24,18.24,0,0,1-2.1.731,2.151,2.151,0,0,1-2.437-.812,2.022,2.022,0,0,1,.126-2.517,34.473,34.473,0,0,0,3.151-4.182,22.421,22.421,0,0,0,1.26-2.436c.126-.244.126-.365-.21-.447-4.244-1.1-6.849-3.735-7.689-7.877A9.907,9.907,0,0,1,7.822.3,10.249,10.249,0,0,1,20.343,8.176,11.624,11.624,0,0,1,20.6,10.247Z" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                    <p className="pb-lg-5">{props.text}</p>
                </div>
                <div className="small-image-testimonial">
                    <img className="shadow" src={props.image} alt="testimonials" />
                    <h5 className="text-center testimonial-name mt-2">{props.name}</h5>
                </div>
            </div>
        </div>
    )
}