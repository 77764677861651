import { useContext, useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import Banner from "../components/Banner";
// import Button from "../components/Button";
import Layout from "../components/Layout";
import Service from "../components/Service";
import ServiceSection from "../components/ServiceSection";

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore, { Autoplay } from 'swiper';
import GlobalState from "../GlobalState";
import { Helmet } from "react-helmet";

export default function Services() {
    SwiperCore.use([Autoplay])

    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
    const [calendlyOpen, setCalendlyOpen] = useState(false)
    const { servicesContent } = useContext(GlobalState);
    const params = useParams();
    const { seoTags } = useContext(GlobalState);

    function scrollSection(id) {
        const element = document.getElementById("section-service-" + id)
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    useEffect(() => {
        if (params.id) {
            const element = document.getElementById(params.id)
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [params.id]);

    useEffect(() => {
        window.triggerScroll();
    }, []);

    function handleClickOutsideCalendly(event) {
        if (!event.target.closest('.calendly')) {
            setCalendlyOpen(false)
        }
    }

    useEffect(() => {
        document.querySelector('body').style.overflow = calendlyOpen ? 'hidden' : null;
        document.querySelector('html').style.overflow = calendlyOpen ? 'hidden' : null;
    }, [calendlyOpen]);

    return (
        <Layout activePage="services" rightPinkBg={true}>
            {seoTags ?
                <Helmet>
                    <title>{seoTags[2].title}</title>
                    <meta name="title" content={seoTags[2].title} />
                    <meta name="description" content={seoTags[2].description} />

                    <meta property="og:title" content={seoTags[2].title} />
                    <meta property="og:description" content={seoTags[2].description} />
                    <meta property="og:image" content={seoTags[2].image} />

                    <meta property="twitter:title" content={seoTags[2].title} />
                    <meta property="twitter:description" content={seoTags[2].description} />
                    <meta property="twitter:image" content={seoTags[2].image} />
                </Helmet>
                :
                null
            }
            {servicesContent ?
                <>
                    <div className="container service-padding-top pb-5 " animate="left">
                        <Banner
                            title={servicesContent.servicesPage.main_title}
                            video={process.env.REACT_APP_BASE_URL + servicesContent.servicesPage.main_video}
                            setCalendlyOpen={() => setCalendlyOpen(true)}
                            buttonLabel={servicesContent.servicesPage.button_label}
                        />
                    </div>

                    <div className="padding-bottom-categories-services position-relative" animate="right">
                        <img className="grey-curl" src="/assets/images/fixed/grey-curl.svg" alt="curl" />
                        <div className="container">
                            <div className="row justify-content-center d-lg-flex d-none">
                                {
                                    servicesContent.services.map((service, index) => (
                                        <div className="col-lg-3 pt-5" key={index} onClick={() => scrollSection(service.id)}>
                                            <Service
                                                id={service.id}
                                                title={service.title}
                                                icon={process.env.REACT_APP_BASE_URL + service.icon}
                                                iconHover={process.env.REACT_APP_BASE_URL + service.icon_hover}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="container d-lg-none d-block pt-5">
                            <div className="px-md-5 px-sm-5 px-3">
                                <Swiper className="mySwiper"
                                    autoplay={{
                                        delay: 3000,
                                    }}
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev'
                                    }}
                                    modules={[Navigation]}
                                    loop={true}
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    breakpoints={{

                                        // when window width is >= 767.98px
                                        767.98: {
                                            slidesPerView: 2,
                                            spaceBetween: 5,
                                            centeredSlides: false
                                        },

                                        575.98: {
                                            slidesPerView: 1.4,
                                            centeredSlides: true
                                        },

                                        450: {
                                            slidesPerView: 1.3,
                                            centeredSlides: true
                                        }
                                    }}>

                                    {
                                        servicesContent.services.map((service, index) => (
                                            <SwiperSlide key={index} onClick={() => scrollSection(service.id)}>
                                                <Service
                                                    id={service.id}
                                                    title={service.title}
                                                    icon={process.env.REACT_APP_BASE_URL + service.icon}
                                                    iconHover={process.env.REACT_APP_BASE_URL + service.icon_hover}
                                                />
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </div>
                        </div>
                        <div className="swiper-button-prev custom-service-arrows">
                            <svg id="Component_15_7" data-name="Component 15 – 7" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                <g className="stroke-arrow" id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#60584d" strokeWidth="2">
                                    <circle cx="25" cy="25" r="25" stroke="none" />
                                    <circle cx="25" cy="25" r="24" fill="none" />
                                </g>
                                <path className="stroke-fill" id="fi-rr-arrow-small-right" d="M15.407,6.518a1.77,1.77,0,0,1,0,2.5L9.579,14.851l18.433.012a1.77,1.77,0,0,1,0,3.54L9.577,18.391l5.829,5.829a1.77,1.77,0,1,1-2.5,2.5L6.556,20.377a5.311,5.311,0,0,1,0-7.511L12.9,6.518A1.77,1.77,0,0,1,15.407,6.518Z" transform="translate(7.391 8.596)" fill="#60584d" />
                            </svg>
                        </div>
                        <div className="swiper-button-next custom-service-arrows">
                            <svg id="Component_15_6" data-name="Component 15 – 6" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                <g className="stroke-arrow" id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#60584d" strokeWidth="2">
                                    <circle cx="25" cy="25" r="25" stroke="none" />
                                    <circle cx="25" cy="25" r="24" fill="none" />
                                </g>
                                <path className="stroke-fill" id="fi-rr-arrow-small-right" d="M19.376,6.518a1.77,1.77,0,0,0,0,2.5L25.2,14.851,6.77,14.863a1.77,1.77,0,1,0,0,3.54L25.2,18.391,19.376,24.22a1.77,1.77,0,1,0,2.5,2.5l6.348-6.346a5.311,5.311,0,0,0,0-7.511L21.879,6.518A1.77,1.77,0,0,0,19.376,6.518Z" transform="translate(7.391 8.596)" fill="#60584d" />
                            </svg>
                        </div>
                    </div>

                    {
                        servicesContent.services.map((serviceDetails, index) => (
                            <div key={index} className="  h-100" id={"section-service-" + serviceDetails.id} animate="left">
                                {console.log(index)}
                                <div className="position-relative h-100">
                                    <ServiceSection
                                        index={index}
                                        id={serviceDetails.id}
                                        icon={process.env.REACT_APP_BASE_URL + serviceDetails.icon_on_image}
                                        image={process.env.REACT_APP_BASE_URL + serviceDetails.image}
                                        price={serviceDetails.price}
                                        title={serviceDetails.title}
                                        subtitle={serviceDetails.description}
                                        label={serviceDetails.button_label}
                                        setCalendlyOpen={() => setCalendlyOpen(true)}
                                    />
                                </div>
                            </div>
                        ))
                    }

                    <div className="pb-5"></div>

                    {/* <div className="container pink-bg-products-top position-relative">
                        <img className="grey-curl-last-section" src="/assets/images/fixed/grey-curl.svg" alt="curl" />
                        <div className="row justify-content-center ">
                            <div className="col-lg-6 col-md-8 col-sm-10 col-12 text-center">
                                <div className="pink-bg-products py-5 shadow">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8 col-md-7 col-sm-9 col-10">
                                            <h2 className="mb-3">{servicesContent.servicesPage.products_cta_title}</h2>
                                            <img className="product" src={process.env.REACT_APP_BASE_URL + servicesContent.servicesPage.products_cta_image} alt="product" />
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-5 d-inline-flex">
                                    <div className="button-arrow position-relative">
                                        <Link className="menu-link" to={servicesContent.servicesPage.products_cta_button_url}>
                                            <Button
                                                buttonClass="button-link mb-0"
                                                buttonColor="button grey-button shadow"
                                                label={servicesContent.servicesPage.products_cta_button_label}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className={"popup text-center" + (calendlyOpen ? "" : " fade-out")} onClick={(e) => handleClickOutsideCalendly(e)}>
                        <div className="close-popup-calendly" onClick={() => setCalendlyOpen(false)}>
                            <svg className="close-pink" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <path className="pink-fill" id="close" d="M11.832,10.136,19.62,2.348A1.3,1.3,0,0,0,17.788.516L10,8.3,2.212.516A1.3,1.3,0,0,0,.38,2.348l7.788,7.788L.38,17.924a1.3,1.3,0,1,0,1.832,1.832L10,11.968l7.788,7.788a1.3,1.3,0,0,0,1.832-1.832Zm0,0" transform="translate(0 -0.136)" fill="#fff" />
                            </svg>
                        </div>
                        <div className="calendly-inline-widget" data-url="https://calendly.com/curlsbyfida/curly-hair-cut" style={{ minWidth: 320, height: 630 }} />
                    </div>
                </>
                :
                null
            }

        </Layout>
    )
}