export default function Galleries(props) {
    return (
        <div className="gallery-hover position-relative pb-lg-5 p-md-3 p-4">
            <div className={"ratio gallery-ratio" + ( props.id === 5 ? " add-border" : " ")}>
                <img src={props.image} alt="gallery" />
                <div className={"overlay-gallery" + ( props.id === 5 ? " add-border" : " ")}></div>
            </div>
            <img className="view" src="/assets/images/fixed/view.svg" alt="view" />
        </div>
    )
}