export default function WhiteBox(props) {
    return (

        <div className="white-boxes flex-column d-flex h-100 p-3">
            <div className="flex-grow-1 h-100">
                <h3 className="mb-3 flex-grow-1 h-100">{props.title}</h3>
            </div>
            <div>
                <p className="mb-0">{props.subtitle}</p>
            </div>
        </div>

    )
}