import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Api from "../components/Api";
import Layout from "../components/Layout";
import Popup from "../components/Popup";
import GlobalState from "../GlobalState";

export default function Contact() {

	const [apiOptions, setApiOptions] = useState({});
	const [contactPage, setContactPage] = useState();
	const { generalData } = useContext(GlobalState);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false)
	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessages, setErrorMessages] = useState({});
	const [error, setError] = useState(false);
	const { seoTags } = useContext(GlobalState);

	useEffect(() => {
		window.triggerScroll();
	});

	useEffect(() => {
		setApiOptions({
			url: '/contact-page',
			method: 'get',
			then: (r) => {
				setContactPage(r.data)
			}
		});
	}, []);

	useEffect(() => {
		window.triggerScroll();
	}, []);

	function contactUs(e) {
		e.preventDefault();
		if (loading) return;
		setLoading(true);
		setApiOptions({
			url: '/submit-contact-form',
			method: 'post',
			data: {
				'first_name': firstName,
				'last_name': lastName,
				'email': email,
				'mobile': phoneNumber,
				'message': message,
			},
			then: (r) => {
				setFirstName('');
				setLastName('');
				setEmail('');
				setPhoneNumber('');
				setMessage('');
				setErrorMessages('');
				setSuccessMessage(true);
			},
			catch422: (errors) => {
				setSuccessMessage('');
				setError(true)
				setErrorMessages(errors);
			},
			finally: () => {
				setLoading(false);
			}
		});
	}

	return (
		<Layout activePage="contact">
			{seoTags ?
				<Helmet>
					<title>{seoTags[4].title}</title>
					<meta name="title" content={seoTags[4].title} />
					<meta name="description" content={seoTags[4].description} />

					<meta property="og:title" content={seoTags[4].title} />
					<meta property="og:description" content={seoTags[4].description} />
					<meta property="og:image" content={seoTags[4].image} />

					<meta property="twitter:title" content={seoTags[4].title} />
					<meta property="twitter:description" content={seoTags[4].description} />
					<meta property="twitter:image" content={seoTags[4].image} />
				</Helmet>
				:
				null
			}
			<Api options={apiOptions} />
			{contactPage ?
				<>
					<div className="container service-padding-top padding-section-bottom">
						<div className="row align-items-center justify-content-between h-100">
							<div className="col-lg-5 col-md-6 order-md-1 order-2 text-center text-md-start" animate="left">
								<h1 className="service-title mb-4">{contactPage.contactSettings.main_title}</h1>
								<div className=" pb-1">
									{console.log(contactPage.contactSettings)}
									<a href="tel:{contactPage.contactSettings.phone}" target="_blank" rel="noreferrer" className="contact-icons d-flex align-items-center pb-4 justify-content-lg-start justify-content-md-start justify-content-center">
										<img src={process.env.REACT_APP_BASE_URL + contactPage.contactSettings.phone_icon} alt="icon" />
										<p className="contact-details mb-0 ms-3">{contactPage.contactSettings.phone}</p>
									</a>

									<a href={"https://wa.me/" + contactPage.contactSettings.whatsapp} target="_blank" rel="noreferrer" className="contact-icons d-flex align-items-center pb-4 justify-content-lg-start justify-content-md-start justify-content-center">
										<img src={process.env.REACT_APP_BASE_URL + contactPage.contactSettings.whatsapp_icon} alt="icon" />
										<p className="contact-details mb-0 ms-3">{contactPage.contactSettings.whatsapp}</p>
									</a>

									<a href={contactPage.contactSettings.translations[0].location_url} target="_blank" rel="noreferrer" className="contact-icons d-flex align-items-center pb-4 justify-content-lg-start justify-content-md-start justify-content-center">
										<img src={process.env.REACT_APP_BASE_URL + contactPage.contactSettings.location_icon} alt="icon" />
										<p className="contact-details mb-0 ms-3">{contactPage.contactSettings.location}</p>
									</a>
								</div>
								<div className="d-lg-flex d-md-flex d-block align-items-center text-lg-start text-md-start ">
									<div className="d-flex  justify-content-lg-start justify-content-md-start justify-content-center pb-lg-0 pb-md-0 pb-3">
										{
											generalData.socialChannels.map((socialChannel, index) =>

												<div key={index}>
													{console.log(generalData)}
													<a href={socialChannel.is_mail === 1 ? "mailto:" + socialChannel.url : socialChannel.url} target="_blank" rel="noreferrer">
														<img className="me-md-3 mx-2 " src={process.env.REACT_APP_BASE_URL + socialChannel.icon_grey} alt="icon" />
													</a>
												</div>
											)
										}
									</div>
									<div className="text-lg-start text-md-start text-center">
										<p className="mb-0 natural-curl">{contactPage.contactSettings.social_media_handle}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-1 d-md-none"></div>
							<div className="col-lg-6 col-md-6 order-md-2 order-1 mb-lg-0 mb-sm-4 mb-4" animate="right">
								<div className="ratio service-banner">
									<img src={process.env.REACT_APP_BASE_URL + contactPage.contactSettings.main_image} alt={contactPage.contactSettings.main_title} id="product" />
								</div>
							</div>
						</div>
					</div>

					<div className="container service-padding-top padding-section-bottom">
						<div className="row">
							<div className="col-lg-6 order-lg-1 order-2 pt-lg-0 pt-5" animate="left">
								<iframe title="map" className="maps" src={contactPage.contactSettings.map_iframe_url} />
							</div>
							<div className="col-lg-6 order-lg-2 order-1" animate="right">
								<div className="pink-bg-contact p-lg-5 p-md-5 p-sm-5 p-4 py-5">
									<h1 className="main-title white mb-4">{contactPage.contactSettings.form_title}</h1>

									<form className="row" onSubmit={contactUs}>
										<div className="col-lg-6">
											<div className="pb-4">
												<input className="contact-form w-100 shadow" placeholder={contactPage.contactSettings.first_name_label} value={firstName} onChange={e => setFirstName(e.target.value)} />
											</div>
											<div className="pb-4">
												<input className="contact-form your_class w-100 shadow" type="number" placeholder={contactPage.contactSettings.mobile_label} value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
											</div>
										</div>
										<div className="col-lg-6">
											<div className="pb-4">
												<input className="contact-form w-100 shadow" placeholder={contactPage.contactSettings.last_name_label} value={lastName} onChange={e => setLastName(e.target.value)} />
											</div>
											<div className="pb-4">
												<input className="contact-form w-100 shadow" placeholder={contactPage.contactSettings.email_label} value={email} onChange={e => setEmail(e.target.value)} />
											</div>
										</div>
										<div className="col-lg-12">
											<div className="pb-5">
												<textarea rows="4" className="contact-form w-100 shadow" placeholder={contactPage.contactSettings.message_label} value={message} onChange={e => setMessage(e.target.value)} />
											</div>

											{/* <div className="button-arrow position-relative" type="submit"> */}
											<button className="button grey-button white contact-button shadow position-relative">
												{loading ?
													<p className="button-link Loading add-space-button mb-0">Loading </p>
													:

													<p className="button-link send-message add-space-button mb-0">
														{contactPage.contactSettings.button_label}
													</p>
												}
												<div className="arrow-button">
													<svg className="arrow-button" id="Group_190" data-name="Group 190" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
														<g className="stroke-arrow" id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#fff" strokeWidth="1">
															<circle cx="14" cy="14" r="14" stroke="none" />
															<circle cx="14" cy="14" r="13.5" fill="none" />
														</g>
														<path className="fill-arrow" id="fi-rr-arrow-small-right" d="M13.121,6.293a1,1,0,0,0,0,1.414L16.413,11,6,11.007a1,1,0,0,0,0,2L16.414,13l-3.293,3.293a1,1,0,1,0,1.414,1.414l3.586-3.585a3,3,0,0,0,0-4.243L14.535,6.293A1,1,0,0,0,13.121,6.293Z" transform="translate(2 2)" fill="#fff" />
													</svg>
												</div>
											</button>
											{/* </div> */}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>

					<Popup
						isOpen={error}
						closePopup=" close-popup-contact text-end"
						close={() => setError(false)}
						blackButton={true}
						modalWindowClass="col-lg-5 col-md-8 col-sm-9 col-9"
						// handleClickOutside={(e) => handleClickOutside(e)}
						positionRelative="position-relative"
					>
						<div className="row popup-contact justify-content-center p-4 py-5">
							<div className="col-lg-8 text-center">
								<img className="message-popup pb-4" src="/assets/images/fixed/error.svg" alt="icon" />

								{
									errorMessages?.first_name ?
										<p className="error-message">{errorMessages?.first_name}</p>
										:
										null
								}

								{
									errorMessages?.email ?
										<p className="error-message">{errorMessages?.email}</p>
										:
										null
								}

								{
									errorMessages?.mobile ?
										<p className="error-message">{errorMessages?.mobile}</p>
										:
										null
								}
								{
									errorMessages?.last_name ?
										<p className="error-message">{errorMessages?.last_name}</p>
										:
										null
								}
								{
									errorMessages?.message ?
										<p className="error-message">{errorMessages?.message}</p>
										:
										null
								}
							</div>
						</div>
					</Popup>

					<Popup
						isOpen={successMessage}
						closePopup=" close-popup-contact text-end"
						close={() => setSuccessMessage(false)}
						blackButton={true}
						modalWindowClass="col-lg-5 col-md-8 col-sm-9 col-9"
						positionRelative="position-relative"
					>
						<div className="row popup-contact justify-content-center p-4 py-5">
							<div className="col-lg-8 text-center">
								<img className="message-popup pb-4" src="/assets/images/fixed/success.svg" alt="icon" />
								<h3 className="error mb-0">{contactPage.contactSettings.success_message}</h3>
							</div>
						</div>
					</Popup>
				</>
				:
				null
			}
		</Layout>
	)
}