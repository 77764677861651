import "swiper/css";
import "swiper/css/navigation";

export default function GalleryPopup(props) {
    return (
        // <div className="ratio gallery-ratio-popup">
        <div className="h-100 w-100">
            <img className="w-100 h-100 popup-gallery" src={props.image} alt="gallery" />
        </div>
        //  </div>
    )
}