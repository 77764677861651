export default function ServiceSection(props) {
    return (
        <div className="postion-relative">
            {console.log(props.index)}
            <img className={"service-plant"  + (props.index % 2 ? " reverse" : " ")} src="/assets/images/fixed/service-bg.svg" alt="plant" />
            <div className="container py-5">
                <div className="pt-5"></div>
                <div className={"row align-items-end justify-content-lg-start justify-content-center position-relative" + (props.index % 2 ? " flex-row-reverse" : " ")}>
                    <div className="col-lg-6 col-md-7 col-sm-10 col-10">
                        <div className="ratio service-image">
                            <img src={props.image} alt={props.title} />
                            <img className={"icon-service" + (props.index % 2 ? " reverse" : " ")} src={props.icon} alt={props.title} />
                            <div className={"service-border" + (props.index % 2 ? " reverse" : " ")}></div>
                        </div>
                    </div>
                    <div className={"col-lg-6 position-relative h-100 flex-column d-flex text-md-start text-sm-center text-center pt-lg-0 pt-md-3" + (props.index % 2 ? " ps-md-5 ps-sm-0" : " pe-md-5 pe-sm-0")}>
                        <div className="limit-height">
                            <p className="mb-3 mt-sm-3 mt-3 mt-md-0 price">{props.price}</p>
                            <h3 className="mb-3">{props.title}</h3>
                            <p className="service-subtitle mb-3">{props.subtitle}</p>
                            <div onClick={() => props.setCalendlyOpen(true)}><p className="book-appointment">{props.label}</p></div>
                            <div className="pink-bg-services"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}