import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function NotFound() {

    useEffect(() => {
        document.body.style.height = '100%'
    }, []);
    return (
        <div className="background-image-404 position-relative">
            <div className="container h-100">
                <div className="not-found">
                    <div className="d-flex">
                        <p className="mb-0">4</p>
                        <img className="image-nf px-lg-5 mx-lg-5 mx-md-5 mx-3" src="/assets/images/fixed/404-img.png" alt="404" />
                        <p className="mb-0">4</p>
                    </div>
                    <div className="button-404">
                        <div className="button-arrow position-relative">
                            <button className="button grey-button white back-home shadow">
                                <Link to="/">
                                    <p className="button-link send-message add-space-button back-home mb-0">
                                        Back to Homepage
                                    </p>
                                </Link>
                            </button>
                            <div className="arrow-button">
                                <svg className="arrow-button" id="Group_190" data-name="Group 190" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                    <g className="stroke-arrow" id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#fff" strokeWidth="1">
                                        <circle cx="14" cy="14" r="14" stroke="none" />
                                        <circle cx="14" cy="14" r="13.5" fill="none" />
                                    </g>
                                    <path className="fill-arrow" id="fi-rr-arrow-small-right" d="M13.121,6.293a1,1,0,0,0,0,1.414L16.413,11,6,11.007a1,1,0,0,0,0,2L16.414,13l-3.293,3.293a1,1,0,1,0,1.414,1.414l3.586-3.585a3,3,0,0,0,0-4.243L14.535,6.293A1,1,0,0,0,13.121,6.293Z" transform="translate(2 2)" fill="#fff" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row h-100 justify-content-center">
                    <div className="col-lg-3 col-md-3 col-4 text-center align-self-center pt-lg-5">
                        <p>4</p>
                    </div>
                    <div className="col-lg-5 col-md-5 col-4 text-center align-self-end">
                    </div>
                    <div className="col-lg-3 col-md-3 col-4 text-center align-self-center pt-lg-5">
                        <p>4</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}