import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

export default function Faq(props) {

    const accordionRef = useRef(null);
    const [accordionHeight, setAccordionHeight] = useState(0);

    useEffect(() => {
        if (props.faqOpenId === props.faq.id) {
            setAccordionHeight(accordionRef.current.scrollHeight)
        } else {
            setAccordionHeight(0);
        }
    }, [props.faqOpenId, props.faq.id]);

    return (
        <>
            <div className={props.faqOpenId === props.faq.id ? " open" : " "} onClick={() => props.faqClick(props.faq.id)}>
                <div className="faq-content align-items-center d-flex ">
                    <h4 className={"title-faq " + (props.faqOpenId === props.faq.id ? "flex-grow-1 mb-0" : "flex-grow-1 mb-0")}>{props.title}</h4>
                    {props.faqOpenId === props.faq.id ?
                        <img src="/assets/images/fixed/minus.svg" alt="faq" style={{ "transition": "all 0.5s" }} />
                        :
                        <img src="/assets/images/fixed/plus.svg" alt="faq" style={{ "transition": "all 0.5s" }} />
                    }
                </div>
                <div className="faq-plus" ref={accordionRef} style={{ height: accordionHeight }}>
                    <div className="mb-0 mt-2" dangerouslySetInnerHTML={{ __html: props.content }} />
                </div>
            </div>
            <hr className="faq-line" />
        </>
    )
}