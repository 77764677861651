
import { useEffect, useState } from "react";
import GlobalState from "./GlobalState";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./pages/404";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Api from "./components/Api";
import axios from 'axios';
// axios.defaults.baseURL = 'https://curls-by-fida-backend.hellotree.dev/api/';
axios.defaults.baseURL = 'https://curlsbyfida.com/api/';

export default function App() {

  const [apiOptions, setApiOptions] = useState({});
  const [generalData, setGeneralData] = useState();
  const [sevicesApiOptions, setSevicesApiOptions] = useState({});
  const [servicesContent, setServicesContent] = useState();
  const [seoTags, setSeoTags] = useState();
  const [instaFeed, setInstaFeed] = useState();
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});

  useEffect(() => {
    setApiOptions({
      url: '/general-data',
      method: 'get',
      then: (r) => {
        setGeneralData(r.data);
        setSeoTags(r.data.seoPages)
      },
    });

    setSevicesApiOptions({
      url: '/services-page',
      method: 'get',
      then: (r) => {
        setServicesContent(r.data);
      },
    });

    setGeneralDataApiOptions({
      url: "/instagram",
      method: 'get',
      then: (res) => {
        setInstaFeed(res.data);
      },
    });
  }, []);



  return (
    <GlobalState.Provider value={{
      generalData, servicesContent, seoTags, instaFeed
    }}>
      <BrowserRouter>
        <Api options={apiOptions} />
        <Api options={sevicesApiOptions} />
        <Api options={generalDataApiOptions} />


        <ScrollToTop />
        <Routes>

          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Services />} />
          <Route exact path="/services/:id" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </GlobalState.Provider>
  );
}

