import { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

export default function WhatWeDo(props) {

    useEffect(() => {
        window.triggerScroll();
    }, []);

    return (
        <div className={props.class}>
            <div className="col-lg-1 "></div>
            <div className={props.column1} animate="left-animate">
                <h2 className="main-title pink mb-3">{props.title}</h2>
                <div className="mb-5" dangerouslySetInnerHTML={{ __html: props.subtitle }} />
                {props.button === true ?
                    <div className="pb-sm-0 pb-5">
                        <div className="button-arrow position-relative">
                            <Link className="menu-link" to="/about">
                                <Button
                                    buttonClass="add-space button-link mb-0"
                                    buttonColor="button pink-button shadow"
                                    label={props.label}
                                />
                            </Link>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            <div className={props.column2} animate="right-animate">
                <div>
                    <div className="first-image position-relative">
                        <img className={props.dots} src="/assets/images/fixed/dots.svg" alt="dots" />
                        <img className={props.flower} src="/assets/images/fixed/flower2.svg" alt="dots" />
                        <div className="row ">
                            <div className="col-6">
                                <div className={props.firstRatio}>
                                    <img className="shadow" src={props.firstImage} alt={props.title} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={props.secondRatio}>
                                    <img className="shadow" src={props.secondImage} alt={props.title} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}