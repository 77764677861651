import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import Popup from "../components/Popup";
import ThumbsSwiper from "../components/ThumbsSwiper";
import "swiper/css";
import "swiper/css/pagination";
import Api from "../components/Api";
import SwiperProduct from "../components/SwiperProduct";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";
import GlobalState from "../GlobalState";

export default function Products() {

    const [productPopup, setProductPopup] = useState(null);
    const [faqOpenId, setFaqOpenId] = useState(null);
    const [apiOptions, setApiOptions] = useState({});
    const [productsSettings, setProductsSettings] = useState();
    const [productsFilter, setProductsFilter] = useState();
    const [filterClickedOpen, setFilterClickedOpen] = useState(1)
    const [products, setProducts] = useState();
    const [pdfSource, setPdfSource] = useState();
    const { seoTags } = useContext(GlobalState);

    function faqClick(id) {
        if (id === faqOpenId) {
            setFaqOpenId(null)
        } else {
            setFaqOpenId(id)
        }
    }
    function handleClickOutside(event) {
        if (!event.target.closest('.bg-swiper')) {
            setProductPopup(false)
        }
    }

    // useEffect(() => {
    //     window.triggerScroll();
    //     window.jQuery("#flipbookContainer").flipBook(
    //         pdfFilter[0].pdf,
    //         { webgl: true }
    //     )
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        window.triggerScroll();
    });

    useEffect(() => {

        setApiOptions({
            url: '/products-page',
            method: 'get',
            then: (r) => {
                setProductsSettings(r.data.productsSettings)
                setProductsFilter(r.data.products)
                setProducts(r.data.products)
                setPdfSource(r.data.products[0].pdf_file)
            }
        });
    }, []);

    function filterClick(filter) {
        setFilterClickedOpen(filter.id)
        setPdfSource(filter.pdf_file)
    }

    useEffect(() => {
        if (pdfSource) {
            window.jQuery("#flipbookContainer").flipBook(
                process.env.REACT_APP_BASE_URL + pdfSource,
                { webgl: true }
            )
        }
    }, [pdfSource]);

    function productPopupClick(product) {
        setProductPopup(product);
    }

    return (

        <Layout activePage="products" rightPinkBg={true} >
            {seoTags ?
                <Helmet>
                    <title>{seoTags[5].title}</title>
                    <meta name="title" content={seoTags[5].title} />
                    <meta name="description" content={seoTags[5].description} />

                    <meta property="og:title" content={seoTags[5].title} />
                    <meta property="og:description" content={seoTags[5].description} />
                    <meta property="og:image" content={seoTags[5].image} />

                    <meta property="twitter:title" content={seoTags[5].title} />
                    <meta property="twitter:description" content={seoTags[5].description} />
                    <meta property="twitter:image" content={seoTags[5].image} />
                </Helmet>
                :
                null
            }
            <Api options={apiOptions} />
            {productsSettings ?
                <>
                    <div className="position-relative">
                        <img className="grey-curl-service" src="/assets/images/fixed/grey-curl.svg" alt="" />
                        <div className="container product-padding-top position-relative" animate="left">
                            <div className="row align-items-center justify-content-between h-100">
                                <div className="col-lg-5 col-md-6 order-md-1 order-2">
                                    <h1 className="service-title pb-lg-4 mb-0 text-lg-start text-md-start text-center">{productsSettings.main_title}</h1>
                                </div>
                                <div className="col-lg-1 d-md-none"></div>
                                <div className="col-lg-6 col-md-6 order-md-2 order-1 mb-lg-0 mb-sm-4 mb-4">
                                    <div className="ratio service-banner">
                                        <img src={process.env.REACT_APP_BASE_URL + productsSettings.main_image} alt={productsSettings.main_title} id="product" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container  " animate="right">
                        <div className="d-flex flex-wrap justify-content-center pt-5 mt-2 ">
                            {
                                productsFilter.map((filter, index) =>
                                    <div className="me-4" key={index}>
                                        <div className={"filter d-flex align-items-center position-relative justify-content-lg-start justify-content-center" + (filterClickedOpen === filter.id ? " active" : " ")} onClick={() => { filterClick(filter) }}>
                                            <img className="small-flower" src="/assets/images/fixed/small-flower.svg" alt="flower" />
                                            <p className="filter-title mb-0 ">{filter.title}</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        {products ?
                            <>
                                {
                                    products.map(product =>
                                        filterClickedOpen === product.id ?
                                            <div key={product.id}>
                                                <div className="row justify-content-center text-center pt-5 mt-2">
                                                    <div className="col-lg-8" animate="left">
                                                        <div className="mb-0 description" dangerouslySetInnerHTML={{ __html: product.description }} />
                                                    </div>
                                                </div>

                                                <div className="pt-5 mt-2">
                                                    <div className="_df_book" id="flipbookContainer"></div>
                                                </div>

                                                {  product.products.length === 0 ? <div className="pb-5"></div> :
                                                    <div className="py-5 my-2" animate="left">
                                                        <div className="text-center">
                                                            <h2 className="main-title pink mb-3">{productsSettings.products_title}</h2>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            {product.products.map(product =>
                                                                <div className="col-lg-3 col-md-6 col-sm-6 pb-3" onClick={() => productPopupClick(product)} key={product.id}>
                                                                    <SwiperProduct
                                                                        title={product.title}
                                                                        subtitle={product.price}
                                                                        image={process.env.REACT_APP_BASE_URL + product.featured_image}
                                                                    />
                                                                </div>
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            null
                                    )
                                }
                            </>
                            :
                            null
                        }

                    </div>

                    <Popup
                        isOpen={productPopup}
                        closePopup=" close-popup-product text-end"
                        close={() => setProductPopup(false)}
                        whiteButton={true}
                        handleClickOutside={(e) => handleClickOutside(e)}
                        modalWindowClass="col-lg-7 col-md-10 col-sm-11 col-11"
                        positionRelative="position-relative"
                    >
                        {productPopup && (
                            <div className="row bg-swiper">
                                <div className="col-lg-6 col-md-6 col-sm-12 orcer-md-1 order-2 white-bg-popup align-self-center pt-5 px-5">
                                    <ThumbsSwiper
                                        thumbs={productPopup.images}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 order-md-2 order-1 pink-bg-popup p-md-5 p-sm-5 p-3 pt-md-5 pt-sm-5 pt-4">
                                    <div className="flex-column d-flex h-100">
                                        <h4 className="mb-3">{productPopup.title}</h4>
                                        <h3 className="mb-3">{products.title}</h3>
                                        <div className="d-flex align-items-center mb-4">
                                            <h5 className="me-5 mb-0">{productPopup.price}</h5>
                                            <h6 className="dosage mb-0">{productPopup.size}</h6>
                                        </div>
                                        <p className="mb-4">{productPopup.description}</p>
                                        <div>
                                            {
                                                productPopup.product_features.map((faq, index) => (
                                                    <Faq
                                                        key={index}
                                                        faq={faq}
                                                        title={faq.title}
                                                        content={faq.content}
                                                        click={() => {
                                                            faqClick();
                                                        }}
                                                        faqClick={faqClick}
                                                        faqOpenId={faqOpenId}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </Popup>
                </>
                :
                null
            }
        </Layout >
    )
}