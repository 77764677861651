import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";


// import required modules
import { Navigation } from "swiper";

export default function ThumbsSwiper(props) {
    const [mainSwiper, setMainSwiper] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (
        <div>
            <Swiper
                spaceBetween={10}
                navigation={false}
                modules={[Navigation]}
                className="mySwiper2 "
                onSwiper={setMainSwiper}
                onSlideChange={(r) => thumbsSwiper.slideTo(r.activeIndex)}
            >
                {JSON.parse(props.thumbs).map((thumb, index) =>
                    <SwiperSlide>
                        <div className="ratio product-swiper-ratio">
                            <img src={process.env.REACT_APP_BASE_URL + thumb} alt="gallery" />
                        </div>
                    </SwiperSlide>
                )
                }
            </Swiper>
            <Swiper
                className="py-5  "
                spaceBetween={10}
                slidesPerView={4}
                // centeredSlides={true}
                slideToClickedSlide={true}
                watchSlidesProgress={true}
                modules={[Navigation]}
                onSlideChange={(r) => mainSwiper.slideTo(r.activeIndex)}
                onSwiper={setThumbsSwiper}
                breakpoints={{
                    1600: {
                        slidesPerView: 5,
                    },

                    1399.98: {
                        slidesPerView: 4,
                    },
                    991.98: {
                        slidesPerView: 3,
                    },

                    // when window width is >= 767.98px
                    767.98: {
                        slidesPerView: 3,
                    },

                    575.98: {
                        slidesPerView: 5,
                    },

                }}
            >
                {JSON.parse(props.thumbs).map((thumb, index) =>
                    <SwiperSlide onClick={() => mainSwiper.slideTo(index)} key={index}>
                        <div className="ratio thumbs-ratio shadow-custom-product">
                            <img src={process.env.REACT_APP_BASE_URL + thumb} alt="gallery" />
                        </div>
                    </SwiperSlide>
                )
                }
            </Swiper>
        </div>
    )
}